<script setup lang="ts"></script>

<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
  </v-app>
  <ShareModalStatus />
</template>

<style scoped></style>
